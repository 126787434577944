.loans{
    &_table{
        border: 1px solid $color-border2;
        box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
        border-radius: 12px;
        // padding: 30px 20px;
        margin: -50px 0 70px;

        &-title{
            padding: 20px;
            border-bottom: 2px solid $color-border2;
        }

        &-empty{
            width: 400px;
            margin: 70px auto;
            display: block;
            text-align: center;

            h5{
                font-weight: 700;
            }

            p{
                color: $color-text2;
            }
            button{
                border: 1px solid $color-primary;
                background: $color-primary;
                color: white;
                padding: 8px 20px;
                margin-top: 10px;
                border-radius: 10px;

                img{
                    margin: -3px 5px 0 0;
                }
            }
        }

        table{
            width: 100%;

            th{
                color: $color-text2;
                padding: 10px;
                font-weight: 400;
            }
            td{
                padding: 10px;
            }

            tr{
                border-bottom: 1px solid $color-border2;
            }

            .td1{
                display: flex;
                align-items: center;

                img{
                    width: 40px;
                    margin: 0 7px 0 0;
                }
                p{
                    margin: 0;
                }
            }
            .td2{
                color: $color-text2;
            }

            .td3{
                display: flex;
                align-items: center;
                background: $color-declinedBg;
                width: 110px;
                border-radius: 20px;
                justify-content: center;
                padding: 4px 10px;
                span{
                    width: 10px;
                    height: 10px;
                    background: $color-declined;
                    border-radius: 50%;
                    margin-right: 5px;
                }
                p{
                    margin: 0;
                    color: $color-declined;
                }
            }
            .td4{
                display: flex;
                align-items: center;
                background: $color-disbursedBg;
                width: 115px;
                border-radius: 20px;
                justify-content: center;
                padding: 4px 10px;
                span{
                    width: 10px;
                    height: 10px;
                    background: $color-disbursed;
                    border-radius: 50%;
                    margin-right: 5px;
                }
                p{
                    margin: 0;
                    color: $color-disbursed;
                }
            }
            .td5{
                background: $color-viewBg;
                width: 145px;
                z-index: 2;
                position: absolute;
                cursor: pointer;
                border-radius: 4px;
                justify-content: center;
                padding: 10px 10px 0;
                p{
                    margin: 0 0 10px;
                    font-size: 15px;
                    color: $color-view;
                }
            }
        }

        &-pagination{
            display: flex;
            margin: 15px 20px 0;
            justify-content: space-between;

            p{
                cursor: pointer;
            }
        }
    }

    &_request{
        border: 1px solid $color-border2;
        box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
        border-radius: 12px;
        padding: 20px;

        &-back{
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            cursor: pointer;
            
            p{
                margin: 0 0 0 5px;
                color: $color-text3;
            }
        }

        label{
            color: $color-label;
        }

        input{
            color: $color-text1;
            border: 1px solid  $color-inputBox;
            padding: 7px 15px;
            margin: 5px 0 20px;
            border-radius: 5px;
            width: 100%;
            outline: none;
        }
        &-btn1{
            border: 1px solid $color-primary;
            background: $color-primary;
            color: white;
            padding: 8px 20px;
            margin-top: 20px;
            border-radius: 10px;
            width: 100%;
        }
        &-btn2{
            border: 1px solid $color-inputBox;
            background: white;
            color: $color-label;
            padding: 8px 20px;
            margin-top: 10px;
            border-radius: 10px;
            width: 100%;
        }

        &-info{
            width: 60px;
            height: 60px;
            border-radius: 50%;
            background: $color-bg1;
            margin-bottom: 25px;
            img{
                width: 100%;
                display: block;
            }
        }
        &-text1{
            color: $color-text4;
        }
        &-text2{
            color: $color-text5;
            margin: 0 0 5px;
            span{
                font-weight: 700;
            }
        }
        
        &-text3{
            font-size: 1.2em;
            width: 80px;
            border-bottom: 4px solid $color-yellow;
        }
        &-text4{
            color: $color-text6;
            margin: 0 0 10px;
            span{
                color: $color-text5;
                float: right;
                font-weight: 700;
            }
        }

        &-radioButtons{
            h5{
                color: $color-text2;
                margin: -15px 0 20px;
            }
            label{
                color: $color-label;
            }
    
            input{
                color: $color-text1;
                border: 1px solid  $color-inputBox;
                padding: 7px 15px;
                margin: 5px 0 20px;
                border-radius: 5px;
                width: 100%;
                outline: none;
            }

            [type="radio"]:checked,
            [type="radio"]:not(:checked) {
                position: absolute;
                left: -9999px;
            }
            [type="radio"]:checked + label,
            [type="radio"]:not(:checked) + label
            {
                position: relative;
                padding-left: 28px;
                cursor: pointer;
                line-height: 20px;
                display: inline-block;
                color: $color-text2;
            }
            [type="radio"]:checked + label:before,
            [type="radio"]:not(:checked) + label:before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 18px;
                height: 18px;
                border: 2px solid $color-primary;
                border-radius: 100%;
                background: #fff;
            }
            [type="radio"]:checked + label:after,
            [type="radio"]:not(:checked) + label:after {
                content: '';
                width: 12px;
                height: 12px;
                background: $color-primary;
                position: absolute;
                top: 3px;
                left: 3px;
                border-radius: 100%;
                -webkit-transition: all 0.2s ease;
                transition: all 0.2s ease;
            }
            [type="radio"]:not(:checked) + label:after {
                opacity: 0;
                -webkit-transform: scale(0);
                transform: scale(0);
            }
            [type="radio"]:checked + label:after {
                opacity: 1;
                -webkit-transform: scale(1);
                transform: scale(1);
            }
        }
    }
}