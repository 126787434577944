*,
*::after,
*::before {
  margin: 0px;
  padding: 0px;
  box-sizing: inherit;
}

html {
  overflow-x: hidden;
}

.u-padding-none {
  padding: 0;
}

.u-margin-none {
  margin: 0;
}

.home {
  margin: 70px 100px;
}
.home_box1 {
  border: 1px solid #EAECF0;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 12px;
  padding: 40px 10px 25px;
}
.home_box1-top {
  display: flex;
  align-items: center;
}
.home_box1-top h4 {
  margin: 0 10px 0 0;
}
.home_box1-top img {
  width: 40px;
}
.home_box1-progress {
  display: flex;
}
.home_box1-progress .text1 {
  color: #9328FF;
}
.home_box1-progress .text2 {
  margin-left: auto;
}
.home_box1-item {
  display: flex;
  align-items: center;
  border-top: 1px solid #CCCCCC;
  padding: 15px 0;
}
.home_box1-item p {
  margin: 0 0 0 7px;
  color: #404040;
}
.home_box1-item img {
  width: 20px;
}
.home_box1-item .arrow {
  margin-left: auto;
}
.home_box2 {
  border: 1px solid #EAECF0;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 12px;
  padding: 30px 20px;
  margin-bottom: 30px;
  min-height: 210px;
}
.home_box2-top {
  display: flex;
  margin-bottom: 20px;
  align-items: flex-start;
}
.home_box2-top h5 {
  font-size: 1.2em;
}
.home_box2-top img {
  margin-left: auto;
}
.home_box2-main {
  display: flex;
}
.home_box2-main p {
  color: #475467;
  margin: 0;
}
.home_box2-main img {
  margin-left: auto;
  width: 100px;
}
.home_box2-main h4 {
  font-size: 2em;
}
.home_box2-request {
  display: flex;
}
.home_box2-request button {
  color: white;
  margin-left: auto;
  padding: 8px 20px;
  margin-top: 20px;
  border-radius: 10px;
}
.home_box2-request-active {
  border: 1px solid #9328FF;
  background: #9328FF;
}
.home_box2-request-inactive {
  border: 1px solid #9E9E9E;
  background: #9E9E9E;
}

.loans_table {
  border: 1px solid #EAECF0;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 12px;
  margin: -50px 0 70px;
}
.loans_table-title {
  padding: 20px;
  border-bottom: 2px solid #EAECF0;
}
.loans_table-empty {
  width: 400px;
  margin: 70px auto;
  display: block;
  text-align: center;
}
.loans_table-empty h5 {
  font-weight: 700;
}
.loans_table-empty p {
  color: #475467;
}
.loans_table-empty button {
  border: 1px solid #9328FF;
  background: #9328FF;
  color: white;
  padding: 8px 20px;
  margin-top: 10px;
  border-radius: 10px;
}
.loans_table-empty button img {
  margin: -3px 5px 0 0;
}
.loans_table table {
  width: 100%;
}
.loans_table table th {
  color: #475467;
  padding: 10px;
  font-weight: 400;
}
.loans_table table td {
  padding: 10px;
}
.loans_table table tr {
  border-bottom: 1px solid #EAECF0;
}
.loans_table table .td1 {
  display: flex;
  align-items: center;
}
.loans_table table .td1 img {
  width: 40px;
  margin: 0 7px 0 0;
}
.loans_table table .td1 p {
  margin: 0;
}
.loans_table table .td2 {
  color: #475467;
}
.loans_table table .td3 {
  display: flex;
  align-items: center;
  background: #FFF1F3;
  width: 110px;
  border-radius: 20px;
  justify-content: center;
  padding: 4px 10px;
}
.loans_table table .td3 span {
  width: 10px;
  height: 10px;
  background: #C01048;
  border-radius: 50%;
  margin-right: 5px;
}
.loans_table table .td3 p {
  margin: 0;
  color: #C01048;
}
.loans_table table .td4 {
  display: flex;
  align-items: center;
  background: #ECFDF3;
  width: 115px;
  border-radius: 20px;
  justify-content: center;
  padding: 4px 10px;
}
.loans_table table .td4 span {
  width: 10px;
  height: 10px;
  background: #027A48;
  border-radius: 50%;
  margin-right: 5px;
}
.loans_table table .td4 p {
  margin: 0;
  color: #027A48;
}
.loans_table table .td5 {
  background: #F9F5FF;
  width: 145px;
  z-index: 2;
  position: absolute;
  cursor: pointer;
  border-radius: 4px;
  justify-content: center;
  padding: 10px 10px 0;
}
.loans_table table .td5 p {
  margin: 0 0 10px;
  font-size: 15px;
  color: #53389E;
}
.loans_table-pagination {
  display: flex;
  margin: 15px 20px 0;
  justify-content: space-between;
}
.loans_table-pagination p {
  cursor: pointer;
}
.loans_request {
  border: 1px solid #EAECF0;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 12px;
  padding: 20px;
}
.loans_request-back {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
}
.loans_request-back p {
  margin: 0 0 0 5px;
  color: #A0A0A0;
}
.loans_request label {
  color: #344054;
}
.loans_request input {
  color: #667085;
  border: 1px solid #D0D5DD;
  padding: 7px 15px;
  margin: 5px 0 20px;
  border-radius: 5px;
  width: 100%;
  outline: none;
}
.loans_request-btn1 {
  border: 1px solid #9328FF;
  background: #9328FF;
  color: white;
  padding: 8px 20px;
  margin-top: 20px;
  border-radius: 10px;
  width: 100%;
}
.loans_request-btn2 {
  border: 1px solid #D0D5DD;
  background: white;
  color: #344054;
  padding: 8px 20px;
  margin-top: 10px;
  border-radius: 10px;
  width: 100%;
}
.loans_request-info {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #EBD6FF;
  margin-bottom: 25px;
}
.loans_request-info img {
  width: 100%;
  display: block;
}
.loans_request-text1 {
  color: #404040;
}
.loans_request-text2 {
  color: #3C3C3C;
  margin: 0 0 5px;
}
.loans_request-text2 span {
  font-weight: 700;
}
.loans_request-text3 {
  font-size: 1.2em;
  width: 80px;
  border-bottom: 4px solid #FFC702;
}
.loans_request-text4 {
  color: #9E9E9E;
  margin: 0 0 10px;
}
.loans_request-text4 span {
  color: #3C3C3C;
  float: right;
  font-weight: 700;
}
.loans_request-radioButtons h5 {
  color: #475467;
  margin: -15px 0 20px;
}
.loans_request-radioButtons label {
  color: #344054;
}
.loans_request-radioButtons input {
  color: #667085;
  border: 1px solid #D0D5DD;
  padding: 7px 15px;
  margin: 5px 0 20px;
  border-radius: 5px;
  width: 100%;
  outline: none;
}
.loans_request-radioButtons [type=radio]:checked,
.loans_request-radioButtons [type=radio]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.loans_request-radioButtons [type=radio]:checked + label,
.loans_request-radioButtons [type=radio]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #475467;
}
.loans_request-radioButtons [type=radio]:checked + label:before,
.loans_request-radioButtons [type=radio]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 2px solid #9328FF;
  border-radius: 100%;
  background: #fff;
}
.loans_request-radioButtons [type=radio]:checked + label:after,
.loans_request-radioButtons [type=radio]:not(:checked) + label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: #9328FF;
  position: absolute;
  top: 3px;
  left: 3px;
  border-radius: 100%;
  transition: all 0.2s ease;
}
.loans_request-radioButtons [type=radio]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
.loans_request-radioButtons [type=radio]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}

.settings {
  padding: 70px 20px;
}
.settings h3 {
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
}
.settings_nav {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
.settings_nav-links {
  display: flex;
  cursor: pointer;
}
.settings_nav-links .active {
  color: #6941C6;
  background: #F9F5FF;
}
.settings_nav-links-item {
  border-radius: 7.5px;
  border: 1px solid white;
  display: flex;
  padding: 5px 10px;
  margin: 0 5px;
  color: #A0A0A0;
}
.settings_nav-links-item p {
  margin: 0;
}
.settings_verification {
  display: flex;
  margin-top: 70px;
  position: relative;
}
.settings_verification_sidebar {
  width: 200px;
  position: absolute;
  padding: 20px 0;
  top: 0;
  z-index: 3;
  left: 0;
}
@media (max-width: 992px) {
  .settings_verification_sidebar {
    z-index: 3;
    left: -300px;
    transition: 2s;
  }
}
.settings_verification_sidebar h5 {
  margin: -10px 0 20px;
  font-weight: 500;
  font-size: 1.2em;
  line-height: 24px;
  color: #101828;
}
.settings_verification_sidebar-items {
  display: flex;
  align-items: center;
  border-radius: 7px;
  padding: 10px 15px;
  margin-bottom: 15px;
  position: relative;
  padding: 5px 10px;
}
.settings_verification_sidebar-items img {
  width: 20px;
  margin: 0 20px 0 0;
}
.settings_verification_sidebar-items p {
  margin: 0 !important;
  white-space: nowrap;
  color: #344054;
}
.settings_verification_sidebar-items input[type=radio] {
  -webkit-appearance: none;
  width: 18px;
  height: 18px;
  border: 1px solid #9328FF;
  border-radius: 50%;
  outline: none;
  margin-right: 20px;
}
.settings_verification_sidebar-items input[type=radio]:hover {
  box-shadow: 0 0 5px 0px orange inset;
}
.settings_verification_sidebar-items:hover {
  background-color: #F0EFF1;
}
.settings_verification_sidebar .active {
  background-color: #F0EFF1;
}
.settings_verification_main {
  position: relative;
  top: 0;
  right: 0;
  padding-left: 240px;
  width: 100%;
}
@media (max-width: 992px) {
  .settings_verification_main {
    padding-left: 0;
  }
}
.settings_verification_main h5 {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #101828;
}
.settings_verification_main p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
}
.settings_verification_main .verify {
  width: 100%;
  padding-bottom: 0.5rem;
}
.settings_verification_main .verify_input {
  padding: 20px 0;
  border-bottom: 1px solid #EAECF0;
  border-top: 1px solid #EAECF0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.settings_verification_main .verify_input label {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
}
.settings_verification_main .verify_input input {
  width: 512px;
  height: 44px;
  background: #F9FAFB;
  border: 1px solid #D0D5DD;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 10px;
}
.settings_verification_main .verify_input-pdf {
  width: 64px;
  height: 64px;
  background: #F2F4F7;
  border-radius: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.settings_verification_main .verify_input-upload {
  width: 100%;
  margin-left: 20px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #FFFFFF;
  border: 1px dashed #EAECF0;
  border-radius: 12px;
}
.settings_verification_main .verify_input-upload-image {
  width: 40px;
  height: 40px;
  background: #F2F4F7;
  border: 6px solid #F9FAFB;
  border-radius: 28px;
  cursor: pointer;
}
.settings_verification_main .verify_input-upload-document {
  text-align: center;
  padding: 10px 0;
}
.settings_verification_main .verify_input-upload-document p {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #475467;
}
.settings_verification_main .verify_input-upload-document span {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #6941C6;
  cursor: pointer;
}
.settings_verification_main .verify_button {
  width: 184px;
  height: 40px;
  background: #7F56D9;
  border: 1px solid #7F56D9;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;
  margin: 20px auto;
  float: right;
}
.settings_profile-top {
  border-bottom: 1px solid #EAECF0;
}
.settings_profile-top h5 {
  margin: 40px 0 0;
}
.settings_profile-top p {
  color: #475467;
}
.settings_profile-item {
  display: flex;
  padding: 20px 0 10px;
  border-bottom: 1px solid #EAECF0;
}
.settings_profile-item-left {
  width: 35%;
}
.settings_profile-item-left .text1 {
  font-weight: 600;
  margin: 0;
  color: #344054;
}
.settings_profile-item-left .text2 {
  color: #475467;
}
.settings_profile-item-right {
  width: 50%;
  display: flex;
  position: relative;
}
.settings_profile-item-right-image {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  overflow: hidden;
}
.settings_profile-item-right-image img {
  width: 100%;
  height: auto;
}
.settings_profile-item-right-change {
  margin-left: auto;
  font-size: 700;
  color: #9328FF;
  cursor: pointer;
}
.settings_profile-item-right input, .settings_profile-item-right select {
  border: 1px solid #D0D5DD;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  width: 100%;
  padding: 8px 15px;
  margin: 0 0 10px;
  color: #667085;
  outline: none;
}
.settings_profile-item-right-eye {
  position: absolute;
  right: 15px;
  width: 20px;
  top: 12px;
  cursor: pointer;
}
.settings_profile-buttons {
  height: 70px;
}
.settings_profile-buttons .submit {
  border: 1px solid #9328FF;
  background: #9328FF;
  color: white;
  padding: 8px 20px;
  margin-top: 20px;
  border-radius: 10px;
  float: right;
}
.settings_profile-buttons .cancel {
  border: 1px solid #D0D5DD;
  background: white;
  color: #344054;
  padding: 8px 20px;
  margin: 20px 10px 0 0;
  border-radius: 10px;
  float: right;
}

.select {
  position: relative;
}
.select_box {
  width: 512px;
  height: 44px;
  background: #F9FAFB;
  border: 1px solid #D0D5DD;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 10px;
  color: #A0A0A0;
  overflow: hidden;
  cursor: pointer;
}
.select_box:active {
  border: 2px solid #9328FF;
}
.select_options {
  width: 100%;
  max-height: 500px;
  overflow-y: scroll;
  position: absolute;
  top: 50px;
  border-radius: 5px;
  left: 0;
  right: 0;
  padding: 10px 10px;
  background-color: white;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.09);
  z-index: 2;
}
.select_options p {
  padding: 5px 5px;
  border-radius: 4px;
  color: #8A8A8A;
  cursor: pointer;
  margin: 0 !important;
}
.select_options p:hover {
  background: rgba(43, 78, 158, 0.09);
}
.select_options_selected {
  background: rgba(43, 78, 158, 0.09);
  color: #101828;
}
.select_arrow {
  position: absolute;
  top: 20px;
  right: 15px;
  pointer-events: none;
}
.select_arrow-type {
  position: absolute;
  top: 20px;
  right: 15px;
  pointer-events: none;
}

.auth {
  display: flex;
  height: 100vh;
}
.auth_section1 {
  width: 45%;
  overflow-y: scroll;
  display: flex;
  align-items: center;
}
@media (max-width: 769px) {
  .auth_section1 {
    width: 100%;
    display: block;
  }
}
.auth_section1-content {
  width: 450px;
  padding: 70px 20px;
  margin: auto;
  display: block;
}
@media (max-width: 560px) {
  .auth_section1-content {
    width: 100%;
  }
}
.auth_section1-content-topImg {
  width: 40px;
  margin-bottom: 10px;
}
.auth_section1-content-topP {
  color: #475467;
}
.auth_section1-content form label {
  color: #344054;
  margin: 20px 0 5px;
}
.auth_section1-content form input {
  border: 1px solid #D0D5DD;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  width: 100%;
  padding: 8px 15px;
  color: #667085;
  outline: none;
}
.auth_section1-content form button {
  background: #9328FF;
  color: white;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #9328FF;
  padding: 7px;
  margin: 30px 0 0;
}
.auth_section1-content form .password-div {
  position: relative;
}
.auth_section1-content form .password-div-eye {
  position: absolute;
  right: 15px;
  width: 20px;
  top: 60px;
  cursor: pointer;
}
.auth_section1-content form .otpWrapper {
  display: flex;
  justify-content: center;
}
@media (max-width: 992px) {
  .auth_section1-content form .otpWrapper {
    justify-content: space-between;
  }
}
.auth_section1-content form .otpInput {
  width: 60px !important;
  margin: 30px 15px 0;
  height: 60px !important;
  font-size: 3em;
}
@media (max-width: 992px) {
  .auth_section1-content form .otpInput {
    width: 20% !important;
    margin: 30px 0px 0;
  }
}
@media (max-width: 769px) {
  .auth_section1-content form .otpInput {
    width: 20% !important;
    margin: 30px 0px 0;
  }
}
.auth_section1-content-warn {
  color: #475467;
  margin: 8px 0 0;
  font-weight: 400;
  font-size: 0.9em;
}
.auth_section1-content-already {
  color: #475467;
  margin-top: 30px;
  text-align: center;
}
.auth_section1-content-already span {
  color: #9328FF;
  cursor: pointer;
}
.auth_section1-content-already-arrow {
  width: 15px;
  margin: -3px 5px 0 0;
}
.auth_section1-content-remember {
  margin: -10px 0 -5px;
  display: flex;
}
.auth_section1-content-remember .rem {
  display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  font-size: 1em;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.auth_section1-content-remember .rem input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.auth_section1-content-remember .checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 18px;
  width: 18px;
  border: 1px solid #D0D5DD;
  border-radius: 5px;
}
.auth_section1-content-remember .rem input:checked ~ .checkmark {
  background-color: #9328FF;
}
.auth_section1-content-remember .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.auth_section1-content-remember .rem input:checked ~ .checkmark:after {
  display: block;
}
.auth_section1-content-remember .rem .checkmark:after {
  left: 5px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
.auth_section1-content-remember-forgot {
  color: #9328FF;
  cursor: pointer;
  margin: 20px 0 0 auto;
}
.auth_section1-copy {
  color: #475467;
  position: absolute;
  bottom: -5px;
  left: 20px;
}
.auth_section2 {
  height: 100vh;
  width: 55%;
  background: linear-gradient(45deg, #101828 0%, #475467 100%);
  color: white;
  position: relative;
}
@media (max-width: 769px) {
  .auth_section2 {
    display: none;
  }
}
.auth_section2-container {
  width: 60%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-60%, -50%);
}
@media (max-width: 1200px) {
  .auth_section2-container {
    width: 70%;
    transform: translate(-50%, -50%);
  }
}
@media (max-width: 992px) {
  .auth_section2-container {
    width: 90%;
  }
}
.auth_section2-container-star {
  width: 60px;
  margin: 0 0 30px;
}
.auth_section2-container-avatar {
  display: flex;
  margin-top: 30px;
}
.auth_section2-container-avatar p {
  margin: 10px 0 0 10px;
}
.auth_section2 h1 {
  font-weight: 400;
  font-size: 3em;
  margin-bottom: 20px;
}
.auth_section2 p {
  font-weight: 400;
}

.dash {
  margin: 0 40px;
}
.dash_nav {
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 0.5px 4px #D1A6FD;
  border-radius: 10px;
  padding: 10px 20px;
  margin-top: 30px;
  box-sizing: border-box;
}
.dash_nav-logo {
  width: 150px;
}
.dash_nav-links {
  display: flex;
  cursor: pointer;
}
.dash_nav-links .active {
  border: 1px solid #9328FF;
  color: #9328FF;
}
.dash_nav-links-item {
  border-radius: 7.5px;
  border: 1px solid white;
  display: flex;
  padding: 5px 10px;
  margin: 0 5px;
  color: #A0A0A0;
}
.dash_nav-links-item svg {
  width: 20px;
  margin: 0 5px 0 0;
}
.dash_nav-links-item p {
  margin: 0;
}
.dash_nav-profile {
  display: flex;
  position: relative;
}
.dash_nav-profile-bell {
  width: 30px;
  margin: 5px 15px 0 0;
}
.dash_nav-profile-pic {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-top: 5px;
  overflow: hidden;
}
.dash_nav-profile-pic img {
  width: 100%;
  height: auto;
}
.dash_nav-profile-arrow {
  width: 10px;
  margin: 5px 0px 0 15px;
  cursor: pointer;
}
.dash_nav-profile-option {
  box-shadow: 0px 0.5px 4px #D1A6FD;
  position: absolute;
  right: -5px;
  top: 40px;
  border-radius: 5px;
  background: white;
}
.dash_nav-profile-option p {
  color: #A0A0A0;
  margin: 10px;
  white-space: nowrap;
}
.dash_nav-profile-notif {
  box-shadow: 0px 0.5px 4px #D1A6FD;
  position: absolute;
  right: -60px;
  top: 40px;
  border-radius: 5px;
  background: white;
  width: 300px;
}
.dash_nav-profile-notif p {
  color: #A0A0A0;
  margin: 10px;
  text-align: justify;
}
.dash_alert {
  display: flex;
  width: 100%;
  background: #FFFCF5;
  border: 1px solid #FEC84B;
  border-radius: 8px;
  padding: 12px 15px;
  margin: 20px 0 50px;
  text-decoration: none;
  align-items: flex-start;
}
.dash_alert h5 {
  font-size: 1em;
  font-weight: 600;
  margin: 0;
  padding: 0 10px;
  color: #B54708;
}
.dash_alert p {
  margin: 0;
  font-size: 0.9em;
  padding: 0 10px;
  color: #B54708;
}
.dash_alert-close {
  margin-left: auto;
  cursor: pointer;
}/*# sourceMappingURL=main.css.map */