.select{
    position: relative;

    &_box{
        width: 512px;
        height: 44px;
        background: #F9FAFB;
        border: 1px solid #D0D5DD;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        padding: 10px;
        // width: 512px;
        // height: 40px;
        // padding: 7px 15px;
        // font-size: 1em;
        // border: 2px solid #C4C4C4;
        // border-radius: 5px;
        color: $color-text3;
        overflow: hidden;
        // margin: 10px 0;
        cursor: pointer;

        // &:focus{
        //     border: 2px solid $color-primary;
        // }
        &:active{
            border: 2px solid $color-primary;
        }
    }

    &_options{
        width: 100%;
        max-height: 500px;
        overflow-y: scroll;
        position: absolute;
        top: 50px;
        border-radius: 5px;
        left: 0;
        right: 0;
        padding: 10px 10px;
        background-color: white;
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.09);
        z-index: 2;
        p{
            padding: 5px 5px;
            border-radius: 4px;
            color: #8A8A8A;
            cursor: pointer;
            margin: 0 !important;
            &:hover{
                background: rgba(43, 78, 158, 0.09);
            }
        }

        &_selected{
            background: rgba(43, 78, 158, 0.09);
            color: #101828
        }
    }

    &_arrow{
        position: absolute;
        top: 20px;
        right: 15px;
        pointer-events: none;
        &-type{
            position: absolute;
            top: 20px;
            right: 15px;
            pointer-events: none;
        }
    }
}