.dash{
    margin: 0 40px;
    &_nav{
        display: flex;
        justify-content: space-between;
        box-shadow: 0px 0.5px 4px $color-border1;
        border-radius: 10px;
        padding: 10px 20px;
        margin-top: 30px;
        box-sizing: border-box;

        &-logo{
            width: 150px;
        }

        &-links{
            display: flex;
            cursor: pointer;

            .active{
                border: 1px solid $color-primary;
                color: $color-primary;
            }

            &-item{
                border-radius: 7.5px;
                border: 1px solid white;
                display: flex;
                padding: 5px 10px;
                margin: 0 5px;
                color: $color-text3;

                svg{
                    width: 20px;
                    margin: 0 5px 0 0;
                }

                p{
                    margin: 0;
                }
            }
        }

        &-profile{
            display: flex;
            position: relative;

            &-bell{
                width: 30px;
                margin: 5px 15px 0 0;
            }

            &-pic{
                width: 30px;
                height: 30px;
                border-radius: 50%;
                margin-top: 5px;
                overflow: hidden;

                img{
                    width: 100%;
                    height: auto;
                }
            }
            &-arrow{
                width: 10px;
                margin: 5px 0px 0 15px;
                cursor: pointer;
            }

            &-option{
                box-shadow: 0px 0.5px 4px $color-border1;
                position: absolute;
                right: -5px;
                top: 40px; 
                border-radius: 5px;
                background: white;

                p{
                    color: $color-text3;
                    margin: 10px;
                    white-space: nowrap;
                }
            }

            &-notif{
                box-shadow: 0px 0.5px 4px $color-border1;
                position: absolute;
                right: -60px;
                top: 40px; 
                border-radius: 5px;
                background: white;
                width: 300px;

                p{
                    color: $color-text3;
                    margin: 10px;
                    text-align: justify;
                }
            }
        }
    }
    
    &_alert{
        display: flex;
        width: 100%;
        background: #FFFCF5;
        border: 1px solid #FEC84B;
        border-radius: 8px;
        padding: 12px 15px;
        margin: 20px 0 50px;
        text-decoration: none;
        align-items: flex-start;

        h5{
            font-size: 1em;
            font-weight: 600;
            margin: 0;
            padding: 0 10px;
            color: #B54708;
        }

        p{
            margin: 0;
            font-size: 0.9em;
            padding: 0 10px;
            color: #B54708;
        }

        &-close{
            margin-left: auto;
            cursor: pointer;
        }
    }
}