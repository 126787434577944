.auth{
    display: flex;
    height: 100vh;

    &_section1{
        width: 45%;
        overflow-y: scroll;
        display: flex;
        align-items: center;
        @media(max-width: $tab-port){
            width: 100%;
            display: block;
        }

        &-content{
            width: 450px;
            padding: 70px 20px;
            margin: auto;
            display: block;
            @media(max-width: $mobile){
                width: 100%;
            }

            &-topImg{
                width: 40px;
                margin-bottom: 10px;
            }

            &-topP{
                color: $color-text2;
            }

            form{
                label{
                    color: $color-label;
                    margin: 20px 0 5px;
                }
                input{
                    border: 1px solid $color-inputBox;
                    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
                    border-radius: 8px;
                    width: 100%;
                    padding: 8px 15px;
                    color: $color-text1;
                    outline: none;
                }
                button{
                    background: $color-primary;
                    color: white;
                    width: 100%;
                    border-radius: 8px;
                    border: 1px solid $color-primary;
                    padding: 7px;
                    margin: 30px 0 0;
                }

                .password-div{
                    position: relative;
                    &-eye{
                        position: absolute;
                        right: 15px;
                        width: 20px;
                        top: 60px;
                        cursor: pointer;
                    }
                }

                .otpWrapper{
                    display: flex;
                    justify-content: center;
                    @media(max-width: $tab-land){
                        justify-content: space-between;
                    }
                }

                .otpInput{
                    width: 60px !important; 
                    margin: 30px 15px 0;
                    height: 60px !important; 
                    font-size: 3em;
                    @media(max-width: $tab-land){
                        width: 20% !important;
                        margin: 30px 0px 0;
                    }
                    @media(max-width: $tab-port){
                        width: 20% !important;
                        margin: 30px 0px 0;
                    }
                }
            }

            &-warn{
                color: $color-text2;
                margin: 8px 0 0;
                font-weight: 400;
                font-size: 0.9em;
            }

            &-already{
                color: $color-text2;
                margin-top: 30px;
                text-align: center;

                span{
                    color: $color-primary;
                    cursor: pointer;
                }

                &-arrow{
                    width: 15px;
                    margin: -3px 5px 0 0;
                }
            }

            &-remember{
                margin: -10px 0 -5px;
                display: flex;

                .rem {
                    display: block;
                    position: relative;
                    padding-left: 25px;
                    cursor: pointer;
                    font-size: 1em;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                }
                .rem input {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                    height: 0;
                    width: 0;
                }
                .checkmark {
                    position: absolute;
                    top: 2px;
                    left: 0;
                    height: 18px;
                    width: 18px;
                    border: 1px solid  $color-inputBox;
                    border-radius: 5px;
                }
                .rem input:checked ~ .checkmark {
                    background-color: $color-primary;
                }
                .checkmark:after {
                    content: "";
                    position: absolute;
                    display: none;
                }
                .rem input:checked ~ .checkmark:after {
                    display: block;
                }
                .rem .checkmark:after {
                    left: 5px;
                    top: 2px;
                    width: 5px;
                    height: 10px;
                    border: solid white;
                    border-width: 0 3px 3px 0;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                }

                &-forgot{
                    color: $color-primary;
                    cursor: pointer;
                    margin: 20px 0 0 auto;
                }
            }
        }

        &-copy{
            color: $color-text2;
            position: absolute;
            bottom: -5px;
            left: 20px;
        }
    }

    &_section2{
        height: 100vh;
        width: 55%;
        background: $color-authBg;
        color: white;
        position: relative;
        @media(max-width: $tab-port){
            display: none;
        }

        &-container{
            width: 60%;
            position: absolute; 
            left: 50%;
            top: 50%;
            transform: translate(-60%, -50%);

            @media(max-width: $desktop){
                width: 70%;
                transform: translate(-50%, -50%);
            }

            @media(max-width: $tab-land){
                width: 90%;
            }

            &-star{
                width: 60px;
                margin: 0 0 30px;
            }

            &-avatar{
                display: flex;
                margin-top: 30px;
                
                p{
                    margin: 10px 0 0 10px;
                }
            }
        }

        h1{
            font-weight: 400;
            font-size: 3em;
            margin-bottom: 20px;
        }
        p{
            font-weight: 400;
        }
    }
}