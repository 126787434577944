.home{
    margin: 70px 100px;
    &_box1{
        border: 1px solid $color-border2;
        box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
        border-radius: 12px;
        padding: 40px 10px 25px;

        &-top{
            display: flex;
            align-items: center;

            h4{
                margin: 0 10px 0 0;
            }
            img{
                width: 40px;
            }
        }

        &-progress{
            display: flex;

            .text1{
                color: $color-primary;
            }

            .text2{
                margin-left: auto;
            }
        }

        &-item{
            display: flex;
            align-items: center;
            border-top: 1px solid $color-border3;
            padding: 15px 0;

            p{
                margin: 0 0 0 7px;
                color: $color-text4;
            }
            img{
                width: 20px;
            }
            .arrow{
                margin-left: auto;
            }
        }
    }

    &_box2{
        border: 1px solid $color-border2;
        box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
        border-radius: 12px;
        padding: 30px 20px;
        margin-bottom: 30px;
        min-height: 210px;

        &-top{
            display: flex;
            margin-bottom: 20px;
            align-items: flex-start;
            h5{
                font-size: 1.2em;
            }
            img{
                margin-left: auto;
            }
        }

        &-main{
            display: flex;
            p{
                color: $color-text2;
                margin: 0;
            }
            img{
                margin-left: auto;
                width: 100px;
            }
            h4{
                font-size: 2em;
            }
        }

        &-request{
            display: flex;
            button{
                color: white;
                margin-left: auto;
                padding: 8px 20px;
                margin-top: 20px;
                border-radius: 10px;
            }

            &-active{
                border: 1px solid $color-primary;
                background: $color-primary;
            }

            &-inactive{
                border: 1px solid $color-text6;
                background: $color-text6;
            }
        }
    }
}