.settings{
    padding: 70px 20px;

    h3{
        font-weight: 600;
        font-size: 30px;
        line-height: 38px;
    }

    &_nav{
        display: flex;
        justify-content: space-between;
        margin-top: 30px;

        &-links{
            display: flex;
            cursor: pointer;

            .active{
                color: $color-settingsNav;
                background: $color-viewBg;
            }

            &-item{
                border-radius: 7.5px;
                border: 1px solid white;
                display: flex;
                padding: 5px 10px;
                margin: 0 5px;
                color: $color-text3;

                p{
                    margin: 0;
                }
            }
        }

    }

    &_verification{
        display: flex;
        margin-top: 70px;
        position: relative;;

        &_sidebar{
            width: 200px;
            position: absolute;
            padding: 20px 0;
            top: 0;
            z-index: 3;
            left: 0;
            
            @media(max-width: $tab-land){
                z-index: 3;
                left: -300px;
                transition: 2s;
            }

            h5{
                margin: -10px 0 20px;
                font-weight: 500;
                font-size: 1.2em;
                line-height: 24px;
                color: #101828;
            }

            &-items{
                display: flex;
                align-items: center;
                border-radius: 7px;
                padding: 10px 15px;
                margin-bottom: 15px;
                position: relative;
                padding: 5px 10px;
    
                img{
                    width: 20px;
                    margin: 0 20px 0 0;
                }
                p{
                    margin: 0 !important;
                    white-space: nowrap;
                    color: $color-label;
                }

                input[type="radio"] {
                    -webkit-appearance:none;
                    width:18px;
                    height:18px;
                    border:1px solid $color-primary;
                    border-radius:50%;
                    outline:none;
                    margin-right: 20px;
                }

                input[type='radio']:hover {
                    box-shadow:0 0 5px 0px orange inset;
                  }

    
                &:hover{
                    background-color: $color-activeLink;
                }
            }
    
            .active{
                background-color: $color-activeLink;
               
            }
        }

        &_main{
            position: relative;
            top: 0;
            right: 0;
            padding-left: 240px;
            width: 100%;

            @media(max-width: $tab-land){
                padding-left: 0;
            }

            h5{
                font-weight: 600;
                font-size: 18px;
                line-height: 28px;
                color: #101828;
            }
            p{
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #475467;
            }

            .verify{
                width: 100%;
                padding-bottom: 0.5rem;

                &_input{
                    padding: 20px 0;
                    border-bottom: 1px solid #EAECF0;
                    border-top: 1px solid #EAECF0;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    label{
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 20px;
                        color: #344054;
                    }
                    input{
                        width: 512px;
                        height: 44px;
                        background: #F9FAFB;
                        border: 1px solid #D0D5DD;
                        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
                        border-radius: 8px;
                        padding: 10px;
                    }
                    &-pdf{
                        width: 64px;
                        height: 64px;
                        background: #F2F4F7;
                        border-radius: 200px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    &-upload{
                        width: 100%;
                        margin-left: 20px;
                        padding: 15px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        background: #FFFFFF;
                        border: 1px dashed #EAECF0;
                        border-radius: 12px;

                        &-image{
                            width: 40px;
                            height: 40px;
                            background: #F2F4F7;
                            border: 6px solid #F9FAFB;
                            border-radius: 28px;
                            cursor: pointer;
                        }

                        &-document{
                            text-align: center;
                            padding: 10px 0;

                            p{
                                margin-bottom: 0;
                                font-weight: 400;
                                font-size: 12px;
                                line-height: 18px;
                                color: #475467;
                            }

                            span{
                                font-weight: 600;
                                font-size: 14px;
                                line-height: 20px;
                                color: #6941C6;
                                cursor: pointer;
                            }
                        }
                    }
                }
                &_button{
                    width: 184px;
                    height: 40px;
                    background: #7F56D9;
                    border: 1px solid #7F56D9;
                    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
                    border-radius: 8px;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 20px;
                    color: #FFFFFF;
                    margin: 20px auto;
                    float: right
                }

            }

            
           
        }
    }

    &_profile{
        &-top{
            border-bottom: 1px solid $color-border2;
            h5{
            margin: 40px 0 0;  
            }

            p{
                color: $color-text2;
            }
        } 

        &-item{
            display: flex;
            padding: 20px 0 10px;
            border-bottom: 1px solid $color-border2;

            &-left{
                width: 35%;
                .text1{
                    font-weight: 600;
                    margin: 0;
                    color: $color-label;
                }
                .text2{
                    color: $color-text2;
                }
            }

            &-right{
                width: 50%;
                display: flex;
                position: relative;
                // border: 1px solid red;

                &-image{
                    width: 70px;
                    height: 70px;
                    border-radius: 50%;
                    overflow: hidden;

                    img{
                        width: 100%;
                        height: auto;
                    }
                }

                &-change{
                    margin-left: auto;
                    font-size: 700;
                    color: $color-primary;
                    cursor: pointer;

                    // &:hover{

                    // }
                }

                input, select{
                    border: 1px solid $color-inputBox;
                    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
                    border-radius: 8px;
                    width: 100%;
                    padding: 8px 15px;
                    margin: 0  0 10px;
                    color: $color-text1;
                    outline: none;
                }

                &-eye{
                    position: absolute;
                    right: 15px;
                    width: 20px;
                    top: 12px;
                    cursor: pointer;
                }
            }
        }

        &-buttons{
            height: 70px;

            .submit{
                border: 1px solid $color-primary;
                background: $color-primary;
                color: white;
                padding: 8px 20px;
                margin-top: 20px;
                border-radius: 10px;
                float: right;
            }
            .cancel{
                border: 1px solid $color-inputBox;
                background: white;
                color: $color-label;
                padding: 8px 20px;
                margin: 20px 10px 0 0;
                border-radius: 10px;
                float: right;
            }
        }
    }
}