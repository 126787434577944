//  Specific breakpoints for responsiveness
$desktop: 1200px;
$tab-land: 992px;
$tab-port: 769px;
$mobile: 560px; 


// Colors
$color-authBg: linear-gradient(45deg, #101828 0%, #475467 100%);
$color-label: #344054;
$color-inputBox: #D0D5DD;
$color-primary: #9328FF;
$color-text1: #667085;
$color-text2: #475467;
$color-text3: #A0A0A0;
$color-text4: #404040;
$color-text5: #3C3C3C;
$color-text6: #9E9E9E;
$color-border1: #D1A6FD;
$color-border2: #EAECF0;
$color-border3: #CCCCCC;
$color-declined: #C01048;
$color-declinedBg: #FFF1F3;
$color-disbursed: #027A48;
$color-disbursedBg: #ECFDF3;
$color-view: #53389E;
$color-viewBg: #F9F5FF;
$color-bg1:#EBD6FF;
$color-yellow: #FFC702;
$color-settingsNav: #6941C6;
$color-activeLink: #F0EFF1;;
